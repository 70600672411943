/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Sen:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
html, body { height: 100%; background-color: #080808; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", "Space Grotesk", sans-serif; }
.w-100{
  width: 100%;
}

*{
  font-family: 'Sen', sans-serif;
}

.heading{
  margin-bottom: 16px;
  font-size: 36px;
}

.red-btn{
  background-color: #b40032;
  color: #fafafa;
  padding: 8px 4px;
  border-radius: 8px !important;
}

.primary-btn{
  background-color: #b40032;
  color: #fafafa;
  width: 100%;
  font-size: medium;
  font-weight: bold;
  padding: 6px 4px !important;
  border-radius: 8px !important;
}

.btn-icon{
  margin-left: 8px;
  height: 20px;
}

.white{
  color: #fafafa;
}

.input-container{
  //padding: 8px;
}

.label{
  font-size: 16px;
  margin-bottom: 4px;
  margin-left: 2px;
  color: #EBEBEB;
}

.q-input{
  border-radius: 8px;
  background-color: #5c5c5c;
  color: #EBEBEB;
  outline: 0;
  border: none;
  padding: 4px 6px;
  width: 100%;
  min-width: 4px;
}

.text-center {
  text-align: center;
}

.q-input-large{
  border-radius: 8px;
  background-color: #5c5c5c;
  color: #EBEBEB;
  outline: 0;
  border: none;
  padding: 4px 6px;
  width: 100%;
  min-width: 4px;
  font-size: xx-large;
}

.otp-container{
  padding-top: 36px;
  text-align: center;
}

.t-left{
  text-align: left !important;
}

.t-right{
  text-align: right !important;
}

.page-content{
  margin: auto;
  text-align: left !important;
  min-height: 100vh;
  max-width: 550px;
}

.fs-20{
  font-size: 20px;
}

.fs-24{
  font-size: 24px;
}

.small-label{
  font-size: 14px;
  color: #f0f0f0;
}

.reg-text{
  font-size: large;
  color: #fafafa;
  margin: 4px;
}

.link{
  color: #e0e0e0;
  text-decoration: underline;
  cursor: pointer;
}

.t-center{
  text-align: center;
}

.grey{
  color: gray !important;
}

.preline {
  white-space: pre-line;
}

.qr-icon{
  margin-top: 8px;
  height: 32px;
}

.fs-36{
  font-size: 36px !important;
}

.cursor-pointer{
  cursor: pointer;
}

.signup .mat-checkbox-layout{
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-wrap!important;
  display: inline-block;
}
